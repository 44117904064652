import React, { Component } from "react";

class CustomSolns extends Component {
  render() {
    if (this.props.data) {
      /*
      var name = this.props.data.name;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
      var productsDownload = this.props.data.productsdownload;
      */
    }

    return (
      <section id="customsolns" className="commonhead">
        <div className="heading">Customized Solutions</div>

        <div className="row" style={{ maxWidth: "100%" }}>
          <div className="six columns " style={{ marginTop: "3%" }}>
            <div data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
              <img
                className="career-pic"
                src="CustoAutomation.png"
                alt="Trulli"
                style={{ width: "100%", verticalAlign: "middle", borderRadius: "1.5em" }}
              ></img>
            </div>
          </div>

          <aside className="six columns ">
            <div  style={{ marginTop: "8%" , backgroundColor:"#0B2A59"}}>
              <div data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
                <p style={{ paddingTop: "5px" }}>
                  In custom automation, machinery and equipment join forces to improve industrial processes via
                  self-optimization. Custom automation is essential and inevitable if customers have certain unique
                  requirements, which can’t be met by the standard solutions exist in the market. Apart from this,
                  Custom automation offers better safety, cost savings, and speed compared to the other general
                  solutions. Moreover, custom automation provides maximum efficiency that allows manufacturers to focus
                  more extensively on quality and productivity. Examples - Cap Closing Machines, Solution Dispensing Machines,
                  
                </p>
              </div>
            </div>
          </aside>
        </div>
      </section>
    );
  }
}

export default CustomSolns;
