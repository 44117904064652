import React, { Component } from "react";

class About extends Component {
  render() {
    if (this.props.data) {
      /*
      var name = this.props.data.name;
      
      var profilepic1= "Jayson.jpeg";
      var profilepic2= "Sunil.jpg";

      
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var productsDownload = this.props.data.productsdownload;
      
      var profilepic = "SkodikaLogo.jpg";
      var profilepic3 = "Jayson.jpg";*/
      var bio = this.props.data.bio;
    }

    return (
      <section id="about">
        <div className="heading">About Us</div>

        <div data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
          <div className="row">
            {/*<div className="three columns">
             <img className="profile-pic"  src={profilepic} alt="Nordic Giant Profile Pic" /> 
         </div>*/}
            <div className="twelve columns main-col">
              <h2 style={{ color: "white" }}>About Skodika Technology Solutions Pvt Ltd</h2>

              <p>{bio}</p>
              <br />
              
              <p>
                <h2>Customer Support:</h2> We develop long-term partnerships with our customers, providing best-in-class
                customer support, integrating our transparent and collaborative culture into the way we work.
              </p>

              <p>
                <h2>Quality:</h2> We follow international manufacturing quality standards, and reinforce the fact that
                we have specific processes and specialists all through the product life cycle.
                <br />
                <br />
                Your automation project will be in good hands with us.
                <br />
                <br />
              </p>
            </div>
          </div>
          <br />
          {/*
      <div className="row">
         <div className="three columns">
           
         </div>
         <div className="nine columns main-col">
            <h2 style={{color:'white'}}>Founders</h2>

         </div>
      </div>
     
      <br/>

*/}

          <div className="row">
            {/*    
         <div className="three columns">
             <img className="profile-pic"  src={profilepic3} alt="Nordic Giant Profile Pic" /> 
         </div>
         */}
            <div className="twelve columns main-col">
              <h2 style={{ margin: "0px" }}> Vishnujith S, </h2>

              <h2 style={{ margin: "0px" }}> Director - Skodika Technology Solutions Pvt Ltd</h2>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
