import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useSnackbar } from "notistack";

const SERVICE_ID = "service_kz3z1pq";
const TEMPLATE_ID = "template_NOWtA33k";

const Contact = props => {
  const [sendername, setSenderName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [senderphone, setSenderPhone] = useState();
  const [sendermessage, setSenderMessage] = useState();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  if (props.data) {
    var name = props.data.name;
    var emailskodika = props.data.email;
    var street1 = props.data.address.street1;
    var street2 = props.data.address.street2;
    var street3 = props.data.address.street3;
    var city = props.data.address.city;
    var state = props.data.address.state;
    var zip = props.data.address.zip;
    var phone = props.data.phone;
    //var email = props.data.email;
    var message = props.data.contactmessage;
  }

  const resetForm = () => {
    setSenderName("");
    setEmail("");
    setSubject("");
    setSenderPhone("");
    setSenderMessage("");
  };
  const handleSubmit = event => {
    var validSenderMessage = true;
    var validMobile = true;
    var validSenderName = true;
    var validEmail = true;

    event.preventDefault();

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      validEmail = false;
    }
    var patternmobile = new RegExp(/^[0-9\b]+$/);

    if (!patternmobile.test(senderphone)) {
      validMobile = false;
    } else if (senderphone.length !== 10) {
      validMobile = false;
    }
    if (sendername && sendername.trim().length < 1) {
      validSenderName = false;
    }
    if (sendermessage && sendermessage.trim().length < 1) {
      validSenderMessage = false;
    }

    if (validSenderMessage && validMobile && validSenderName && validEmail) {
      enqueueSnackbar("Please wait..", { variant: "warning", preventDuplicate: true });
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, "#contactForm", "user_WJZKdW1MgyTBDAlmJEAor").then(
        function(response) {
          setSenderName("");
          setEmail("");
          setSubject("");
          setSenderPhone("");
          setSenderMessage("");

          enqueueSnackbar("SUCCESS! Email sent", { variant: "success" });
        },
        function(error) {
          enqueueSnackbar("FAILED! Email not sent", { variant: "error" });
        }
      );
    } else {
      closeSnackbar();
      if (!validEmail) enqueueSnackbar("Invalid email address, e-mail not sent", { variant: "error" });
      else if (!validMobile) enqueueSnackbar("10 digits mobile number required, e-mail not sent", { variant: "error" });
      else if (!validSenderName) enqueueSnackbar("Sender Name Required, e-mail not sent", { variant: "error" });
      else enqueueSnackbar("Please fill the required details, e-mail not sent", { variant: "error" });
    }
  };

  return (
    <section id="contact">
      <div className="heading">Contact Us</div>
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        {/* IMP   #contact p.lead      layout.css   ----------- */}
        <div className="ten columns" style={{ color: "#eee" }}>
          <p className="lead">{message}</p>
        </div>
      </div>

      <div className="row">
        <div className="six columns offset-2">
          <div className="subcard" style={{ paddingLeft: "1em", paddingRight: "1em" }}>
            <form onSubmit={handleSubmit} id="contactForm" name="contactForm" style={{ padding: 0, margin: 0 }}>
              <fieldset>
                <div>
                   {/*    #contact label    -----   layout.css   -----    */}
                  <label htmlFor="contactName">
                    Name <span className="required">*</span>
                  </label>
                  <span style={{ display: "block", overflow: "hidden" }}>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="from_name"
                      name="from_name"
                      value={sendername}
                      autoComplete="new-password"
                      onChange={e => setSenderName(e.target.value)}
                      style={{ width: "100%", backgroundColor: "#0B2A59" }}
                    />
                  </span>
                </div>

                <div>
                  <label htmlFor="contactEmail">
                    Email <span className="required">*</span>
                  </label>
                  <span style={{ display: "block", overflow: "hidden" }}>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="from_email"
                      name="from_email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      autoComplete="new-password"
                      style={{ width: "100%", backgroundColor: "#0B2A59" }}
                    />
                  </span>
                </div>

                <div>
                  <label htmlFor="contactMobile">
                    Mob. <span className="required">*</span>
                  </label>
                  <span style={{ display: "block", overflow: "hidden" }}>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="from_phone"
                      name="from_phone"
                      value={senderphone}
                      onChange={e => setSenderPhone(e.target.value)}
                      autoComplete="new-password"
                      style={{ width: "100%", backgroundColor: "#0B2A59"}}
                    />
                  </span>
                </div>

                <div>
                  <label htmlFor="contactSubject">Subject</label>
                  <span style={{ display: "block", overflow: "hidden" }}>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="from_subject"
                      name="from_subject"
                      value={subject}
                      onChange={e => setSubject(e.target.value)}
                      autoComplete="new-password"
                      style={{ width: "100%", backgroundColor: "#0B2A59"}}
                    />
                  </span>
                </div>

                {/* IMP      textarea { min-height: 20px; }   ------ default.css  */}
                <div>
                  <label htmlFor="contactMessage">
                    Message <span className="required">*</span>
                  </label>
                  <span style={{ display: "block", overflow: "hidden" }}>
                    <textarea
                      cols="50"
                      rows="2"
                      id="message_html"
                      name="message_html"
                      value={sendermessage}
                      onChange={e => setSenderMessage(e.target.value)}
                      style={{ resize: "none", width: "100%", backgroundColor: "#0B2A59"}}
                    ></textarea>{" "}
                  </span>
                </div>
                <br />
                <div>
                  <label htmlFor="contactMessage"> </label>
                  <span style={{ display: "flex", justifyContent: "center", overflow: "hidden" }}>
                    <button className="nav" type="submit" style={{ marginRight: "2px", fontSize: "18px" }}>
                      Send
                    </button>
                    <button
                      className="nav"
                      type="button"
                      onClick={resetForm}
                      style={{ marginRight: "2px", fontSize: "18px" }}
                    >
                      Clear
                    </button>
                  </span>
                </div>
              </fieldset>
            </form>
          </div>

          {/* IMP subard ---------- laoyout.css   */}
        </div>
        <aside className="four columns footer-widgets">
          <div className="subcard" style={{ paddingLeft: "1em", paddingRight: "1em", backgroundColor: "#0b2a59" }}>
            <h2>Our Address: </h2>
            <p>
           
              <b>{name}</b>
            </p>
            <p>
      
              {street1} <br />
              {street2} <br />
              {street3} <br />
              {city}, {state},<br />
              India, Pin {zip} <br />
            </p>
            <p>
              <b>{emailskodika} </b>
              <br />
              <span>  
                <b>Mob. {phone}</b>
              </span>
            </p>
            <br />
          </div>  
          {/*
            <div className="widget widget_tweets">
              <h4 className="widget-title">Latest Tweets</h4>
              <ul id="twitter">
                <li>
                  <span>
                    This is Photoshop's version of Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean
                    sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum
                    <a href="#">http://t.co/CGIrdxIlI3</a>
                  </span>
                  <b>
                    <a href="#">2 Days Ago</a>
                  </b>
                </li>
                <li>
                  <span>
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                    <a href="#">http://t.co/CGIrdxIlI3</a>
                  </span>
                  <b>
                    <a href="#">3 Days Ago</a>
                  </b>
                </li>
              </ul>
            </div>
*/}
        </aside>
      </div>
    </section>
  );
};

export default Contact;
