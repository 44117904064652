import React, { Component } from "react";

class Datalog extends Component {
  render() {
    if (this.props.data) {
      /*
      var name = this.props.data.name;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
      var productsDownload = this.props.data.productsdownload;
      */
    }

    return (
      <section id="datalog" className="commonhead">
        <div className="heading">Plantwide Data Logging</div>

        <div className="row" style={{ maxWidth: "100%" }}>
          <div className="six columns " style={{ marginTop: "3%" }}>
            <div data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
              <img
                className="career-pic"
                src="ISolutions.png"
                alt="Trulli"
                style={{ width: "100%", verticalAlign: "middle", borderRadius: "1.5em" }}
              ></img>
            </div>
          </div>

          <aside className="six columns ">
            <div  style={{ marginTop: "8%" }}>
              <div data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
                <p style={{ paddingTop: "5px" }}>
                  Industrial Data Loggers and Data Acquisitions systems are widely used in all major industries, for
                  process monitoring and control. Data from these distributed systems are to be collected for plant-wide
                  Predictive Analysis and Maintenance (Target : Engineering & Quality), Process optimisation and Quality
                  improvement. Bringing the data from a wide set of disparate systems with different communication protocols
                  to a central repository is challenging task. SKODIKA provides this capability to customers to bring
                  data from all levels of the automation, viz., sensors, controllers, SCADA, MES and ERP to a central
                  repository for analysis.
                </p>
              </div>
            </div>
          </aside>
        </div>
      </section>
    );
  }
}

export default Datalog;
