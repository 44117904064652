import React, { Component } from 'react';
/* import ReactGA from 'react-ga'; */
import $ from 'jquery';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
//import Products from './Components/Products';
import Contact from './Components/Contact';
//import Portfolio from './Components/Portfolio';
//import Careers from './Components/Careers';
import Home from './Components/Home';
import DataLog from './Components/DataLog';
import ISolutions from './Components/ISolutions';
import CustomSolns from './Components/CustomSolns';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..


class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      productsData: {}
    };
/* 
    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname); */

  }

  getProductsData(){
    $.ajax({
      url:'./productsData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({productsData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    AOS.init({offset: 600});
    this.getProductsData();
  }

  render() {
    return (
      <div>
        <Header data={this.state.productsData.main}/>
       {/* <Products data={this.state.productsData.products}/>
        <Portfolio data={this.state.productsData.portfolio}/> 
        <div style={{marginLeft:'15%'}}>
        <img  data-aos="fade-left" data-aos-easing="linear" data-aos-duration="200"  src="home.jpeg" alt="Trulli" width="80%"></img>
        </div>
*/}
        <Home data={this.state.productsData.main}/>
        
        <DataLog  data={this.state.productsData.main}/>
        <ISolutions data={this.state.productsData.main}/>
        <CustomSolns data={this.state.productsData.main}/>
        <About data={this.state.productsData.main}/>
        <Contact data={this.state.productsData.main}/>
        <Footer data={this.state.productsData.main}/>
        
      </div>
    );
  }
}

export default App;
