import React, { useState, useEffect } from "react";
import ParticlesBg from "particles-bg";

function Header(props) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  if (props.data) {
    // var project = this.props.data.project;
    // var github = this.props.data.github;
    // var name = this.props.data.name;
    var description = props.data.description;
    //var city = this.props.data.address.city;
    /*
      var networks = this.props.data.social.map(function(network) {
        return (
          <li key={network.name}>
            <a href={network.url}>
              <i className={network.className}></i>
            </a>
          </li>
        );
      });*/ {
      /* #0b2a59;   #0b2a59*/
    }
  }

  return (
    <header id="header">
      {isMobile ? (
        <ParticlesBg type="cobweb" num={50} color="#000524" bg={true} />
      ) : (
        <ParticlesBg type="cobweb" num={100} color="#000524" bg={true} />
      )}
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
           
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
           
        </a>

        {/*  css file   default.css    search for    6. Buttons   */}

        <ul id="nav" className="nav" style={{ marginTop: "2px"}}>
          <li >
            <a className="smoothscroll" href="#home" style={{ padding: 0, marginRight: "2px", maxWidth:"7.5rem" }}>
              <button >Home</button>
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#datalog" style={{ padding: 0, marginRight: "2px" ,maxWidth:"7.5rem"}}>
              <button>DataLog</button>
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#isolutions" style={{ padding: 0, marginRight: "2px",maxWidth:"7.5rem" }}>
              <button>iSolutions</button>
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#customsolns" style={{ padding: 0, marginRight: "2px", maxWidth:"7.5rem" }}>
              <button>Custom</button>
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#about" style={{ padding: 0, marginRight: "2px" ,maxWidth:"7.5rem"}}>
              <button>About</button>
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#contact" style={{ padding: 0, marginRight: "2px",maxWidth:"7.5rem" }}>
              <button>Contact</button>
            </a>
          </li>
        </ul>
      </nav>

      {/* header .banner-text h3      ----    layout.css     -----    */}
      <div className="row banner">
        <div className="banner-text" style={{ marginTop: "150px" }}>
          <img className="career-pic" src="Logo_Final.png" alt="Trulli" width="60%" height="20%"></img>
 
          {/* <h1 className="responsive-headline">{name}</h1>   @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap');  */}
          <h3>{description}.</h3>
          <hr />
          {/*
            <ul className="social">
              <a href="#about" className="button btn project-btn">
                <i className="fa fa-book"></i>More
              </a>*/}

          {/* <a href={github} className="button btn github-btn"><i className="fa fa-github"></i>Github</a> */}
          {/*   </ul> */}
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
}

export default Header;
