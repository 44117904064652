import React, { Component } from "react";

class Home extends Component {
  render() {
    if (this.props.data) {
      /*
      var name = this.props.data.name;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
      var productsDownload = this.props.data.productsdownload;
      */
    }

    return (
      <section id="home" className="commonhead">
        <div className="heading">What We Do..</div>
        {/* <img className="career-pic" src="home.jpeg" alt="Trulli" width="100%" height="20%"></img>
         */}{" "}
        <div className="row">
          <div className="twelve columns main-col">
            <br />
            <br />
            {/* IMP      #home p    in layout.css      IMP */}
            <p data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
              Things are Changing and Challenging ! . As Part of Industry 4.0 revolution , factories are trying to
              integrate new technologies into their production facilities, throughout their operations. Industry 4.0
              systems are characterised by: 
            
<br/><br/> 
<li style={{marginLeft:'1rem', lineHeight:'160%'}}> more automation than in the third industrial revolution,</li>
<li style={{marginLeft:'1rem',lineHeight:'160%'}}> the bridging of the physical and digital world through cyber-physical systems, enabled by Industrial IoT,</li>
<li style={{marginLeft:'1rem',lineHeight:'160%'}}> a shift from a central industrial control system to one where smart products define the production steps,</li>
<li style={{marginLeft:'1rem',lineHeight:'160%'}}> closed-loop data models and control systems and</li>
<li style={{marginLeft:'1rem',lineHeight:'160%'}}> personalization/customization of products.</li>
<br/> 
</p>
            <p data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
              This Digital Transformation leads to increased automation, predictive analysis & maintenance,
              self-optimization of process improvements, new levels of efficiencies and responsiveness, which was not
              previously possible.
            </p>

            <p data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
              SKODIKA Provides Automation Solutions for these challenging environments by incorporating Engineering,
              Production, Quality, and Supply Chain Departments together for better quality and productivity.{" "}
            </p>

            <div className="row">
              <div className="columns contact-details"></div>
              {/*               <div className="columns download">
                <p>
                  <a href={productsDownload} className="button">
                    <i className="fa fa-download"></i>Download Details
                  </a>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Home;
