import React, { Component } from "react";

class ISolutions extends Component {
  render() {
    if (this.props.data) {
      /*
      var name = this.props.data.name;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
      var productsDownload = this.props.data.productsdownload;
      */
    }

    return (
      <section id="isolutions" className="commonhead">
        <div className="heading">Intelligent Automation Solutions</div>

        <img
          className="career-pic"
          src="IntelligentAutomationSolution.jpg"
          alt="Trulli"
          width="100%"
          height="20%"
        ></img>
        <div className="row">
          <div className="twelve columns main-col">
            {/* IMP    ----   #isolutions p  -----  in layout.css      IMP */}

            <p data-aos="flip-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1200">
              The value of Intelligent Automation (IA) in the world today, across industries, is unmistakable. With the
              automation of tasks through IA, businesses can reduce their costs as well as establish more consistency
              within their workflows. Intelligent automation simplifies processes, frees up resources and improves
              operational efficiencies, and it has a variety of applications. SKODIKA provides Intelligent Automation
              capability, that comprises a powerful platform that collects, processes and analyses vast amounts of data
              to gain insights on the process performance, identify outliers and abnormal events towards triggering
              appropriate alerts, and helps to develop various optimisation strategies.
            </p>

            <div className="row">
              <div className="columns contact-details"></div>
              {/*               <div className="columns download">
                <p>
                  <a href={productsDownload} className="button">
                    <i className="fa fa-download"></i>Download Details
                  </a>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ISolutions;
