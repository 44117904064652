import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "semantic-ui-css/semantic.min.css";

import { SnackbarProvider } from "notistack";

ReactDOM.render(
  <SnackbarProvider>
    <App />,
  </SnackbarProvider>,
  document.getElementById("root")
);
registerServiceWorker();
